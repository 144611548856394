import logo from './assets/Logotype - yellow.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          <span className="animate-text first">Global,</span>{' '}
          <span className="animate-text second">Flexible,</span>{' '}
          <span className="animate-text third">Banking</span>
        </h1>
      </header>
    </div>
  );
}

export default App;
